import { useMeQuery } from "@generated/graphql";
import { ListItemIcon, ListItemText, MenuItem } from "@infinitaslearning/pixel-design-system";
import MessagesIcon from "@infinitaslearning/pixel-icons/Messages";
import { useBrand } from "@utils/use-brand";
import { useTranslation } from "react-i18next";

const TALLY_MODAL_WIDTH = 500;
const TALLY_AUTOCLOSE_MS = 5000;
const TALLY_FORM_ID_FR_BE_LOCALE = "me2xro";
const TALLY_FORM_ID_NL_BE_LOCALE = "wzZAE1";

export const TallyFeedbackButton = () => {
  const { t } = useTranslation("common", { keyPrefix: "help-navigation" });
  const isFrenchLocale = useBrand().locale === "fr-be";
  const tallyFormId = isFrenchLocale ? TALLY_FORM_ID_FR_BE_LOCALE : TALLY_FORM_ID_NL_BE_LOCALE;

  const { data } = useMeQuery();
  const meData = data?.me;

  return (
    <MenuItem
      onClick={() => {
        window?.Tally?.openPopup(tallyFormId, {
          layout: "modal",
          width: TALLY_MODAL_WIDTH,
          autoClose: TALLY_AUTOCLOSE_MS,
          hiddenFields: {
            userId: meData?.id,
          },
        });
      }}
    >
      <ListItemIcon>
        <MessagesIcon />
      </ListItemIcon>
      <ListItemText>{t("feedback")}</ListItemText>
    </MenuItem>
  );
};
